<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card :loading="loading" :disabled="loading">
          <v-row>
            <v-col style="text-align:center;" cols="12">
              <h2>ePages</h2>
            </v-col>
          </v-row>

          <!--<v-toolbar color="primary" flat>
            <v-toolbar-title class="orange--text">STRATO ePages Schnittstelle</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>-->

          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab key="setup" v-if="false"> {{ $t('settings.lang_setup') }} </v-tab>
            <v-tab key="settings"> {{ $t('settings.lang_settings') }} </v-tab>

            <v-tabs-items touchless v-model="tab">
              <v-tab-item v-if="false" key="setup">
                <!--Setup-->
                <v-card-text>
                  <v-container fluid class="pa-2">
                    <v-row >
                      <v-col cols="12">
                        <v-switch
                          v-model="active"
                          inset :loading="loading" :disabled="loading"
                          :label="$t('settings.lang_settings_activ')" hide-details
                        >
                        </v-switch>
                      </v-col>
                      <v-col cols="12" align="center">
                        <v-btn-toggle v-model="strato_type" dense color="primary">
                          <v-btn class="ma-0" :input-value="0">
                            {{ $t('settings.lang_useApiToken') }}
                          </v-btn>
                          <v-btn class="ma-0" :input-value="1">
                            {{ $t('settings.lang_useClientKeySecret') }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col v-if="strato_type===0">
                        <v-text-field :disabled="loading" outlined dense :label="$t('settings.lang_apiUrl')" :placeholder="apiurl.toString()" v-model="apiurl"></v-text-field>
                        <v-text-field :disabled="loading" outlined dense :label="$t('settings.lang_accessToken')" :placeholder="token.toString()" v-model="token"></v-text-field>
                      </v-col>
                      <v-col v-else>
                        <v-text-field :disabled="loading" outlined dense :label="$t('settings.lang_apiUrl')" :placeholder="apiurl.toString()" v-model="apiurl"></v-text-field>
                        <v-text-field :disabled="loading" outlined dense :label="$t('settings.lang_clientKey')" v-model="strato_clientKey"></v-text-field>
                        <v-text-field :disabled="loading" outlined dense :label="$t('settings.lang_clientSecret')" v-model="strato_clientSecret"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-tab-item>
              <v-tab-item key="settings">
                <!--Settings-->
                <v-card-text>
                  <v-container fluid class="pt-7 pa-2">
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="ePages_storageId"
                          :items="storages"
                          :label="$t('settings.lang_storages')"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="loading" :loading="loading" @click="saveSettings">{{ $t('generic.lang_save') }}</v-btn>
          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "Strato",
  data() {
    return {
      loading: false,
      token: "",
      apiurl: "",
      strato_type:0,
      strato_clientSecret:null,
      strato_clientKey:null,
      ePages_storageId: null,
      storages: [],
      active: false,
      tab: 0,
    }
  },
  watch:{
    strato_type(val){
     // console.log(val)
    }
  },
  mounted() {
    this.getSettings();
    this.getStorages();
  },

  methods: {
    getStorages() {
      this.loading = true;

      this.axios.post(ENDPOINTS.DATATABLES.ERP.STORAGEOVERVIEW).then((res) => {
        if (res.status == 200) {
          res.data.aaData.forEach((data) => {
            this.storages.push({
              id: parseInt(data[0]),
              name: data[1],
            });
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    getSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.STRATO.SETTINGS.GET).then((res) => {
        if(res.data.success) {
          this.token = res.data.strato_epagesApiToken;
          this.apiurl = res.data.strato_epagesApiURL;
          this.active = res.data.strato_epagesActive;
          this.strato_type = res.data.strato_type;
          this.strato_clientSecret = res.data.strato_clientSecret;
          this.strato_clientKey = res.data.strato_clientKey;
          this.ePages_storageId = parseInt(res.data.ePages_storageId);
        }
        else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    saveSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.STRATO.SETTINGS.UPDATE, {
        strato_epagesApiToken: this.token,
        strato_epagesApiURL: this.apiurl,
        strato_epagesActive: this.active,
        strato_type: this.strato_type,
        strato_clientSecret: this.strato_clientSecret,
        strato_clientKey: this.strato_clientKey,
        ePages_storageId: this.ePages_storageId,
      }).then((res) => {
        if(res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        }
        else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  }

}
</script>